@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

/* DEBUG */
/* * { border: 1px solid red !important; } */

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-black: #000000;
    --color-white: #fff;
    --color-offwhite: #fcfcfc;
    --color-lightgrey: #f0f0f0;

    --transition: all 400ms ease;
}

html {
    scroll-behavior: smooth;
}

body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-offwhite);
    line-height: 1.1;
}

/* ================ GENERAL STYLES ================ */


/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){

}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    
}