.navbar {
    z-index: 999;
    width: 100%;
    position: fixed;
}

.navbar li{
    list-style: none;
}

.navbar a{
    font-size: 20px;
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
}

nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-weight: 100;
}

.devgav {
    font-weight: 200;
    padding-right: 1rem;
}

.nav-link{
    transition: 0.3s ease;
}

.nav-link:hover{
    color: rgba(255, 255, 255, 1);
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.75);
}

/* ============== SCROLL STYLES ============== */

.scroll {
    background-color: rgba(219, 219, 219, 0.55);
}

.scroll a{
    color: black;
}

.scroll a:hover{
    color:rgb(93, 93, 93);
}

.scroll .bar {
    background-color: rgba(0, 0, 0, 0.75);
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){
    .navbar {
        width: 100%;
    }

    nav {
        justify-content: space-between;
    }

    .hamburger{
        display: block;
    }

    .hamburger.active .bar:nth-child(2){
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu{
        position: fixed;
        left: -100%;
        top: 61px;
        /* gap: 0; */
        flex-direction:column;
        width: 100%;
        align-items: flex-start;
        transition: 0.3s;
        padding-bottom: 1rem;
    }

    .nav-item{
        margin: 0 1.4rem;
    }

    .nav-menu.active{
        left: 0;
    }

    .scroll .nav-menu{
        background-color: rgba(219, 219, 219, 0.55);
    }
    
}