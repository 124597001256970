@import url('https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css');
.skills {
    background: var(--color-offwhite);
    padding: 5rem 2rem;
    display:grid;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 50px));
    justify-content: center;
    align-items: center;
}

.skill {
    margin: 10px;
    text-align: center;
}

.skill h2{
    font-size: 30px;
    font-weight: 400;
}

.skill p{
    font-size: 15px;
    font-weight: 200;
}

.image-grid {
    --gap: 50px;
    --num-cols: 2;
    --row-height: 30px;
    justify-items: center;
    align-items: center;

    /* box-sizing: border-box; */
    padding: var(--gap);

    display: grid;
    grid-template-columns: repeat(var(--num-cols), 40px);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
}

.image-grid > img {
    width: 70px;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){

}


/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){

}
