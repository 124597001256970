header {
    background: rgb(25,40,65);
    background: linear-gradient(45deg, rgba(25, 40, 65,.25) 0%, rgba(69,89,122,.25) 100%);   
    position: relative;
    height: 100vh;
    overflow: hidden;
    text-align: center;
}

.header__container {
    position: absolute;
    color: var(--color-white);
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header__container h1 {
    font-size: 130px;
    font-weight: 400;
}

.header__container h2 {
    font-size: 24px;
    font-weight: 300;
}

.scrollto {
    font-size: 60px;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    animation: bounce 1.2s;
    animation-iteration-count: infinite;
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.scrollto:hover {
    color: rgb(66, 66, 66);
    animation: bounce__hover 1.2s;
    animation-iteration-count: infinite;
}

@keyframes bounce__hover {
    10% {bottom: 20px; color: rgba(120, 120, 120, 1);}
    50% {bottom: 16px; color: rgba(120, 120, 120, 1);}
}

@keyframes bounce {
    10% {bottom: 20px; color: rgba(255, 255, 255, 1)}
    50% {bottom: 16px; color: rgba(255, 255, 255, .6)}
}

/* ==== HEADER SOCIALS ==== */

.cta {
    padding-top: .5rem;
    font-size: 45px;
    color: white;   
}

.socials {
    color: white;
    padding: 0 4px;
}

.socials:hover {
    color: rgba(120, 120, 120, 0.8);
    transition: var(--transition);
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){
    .header__container h1 {
        font-size: 115px;
        font-weight: 500;
    }
    .header__container h2 {
        font-size: 22px;
        font-weight: 400;
    }

    .cta {
        font-size: 43px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){

    .header__container h1 {
        font-size: 60px;
        font-weight: 500;
    }
    .header__container h2 {
        font-size: 14px;
        font-weight: 400;
    }

    .cta {
        font-size: 40px;
    }

    .scrollto{
        bottom: 90px;
    }

    @keyframes bounce__hover {
        10% {bottom: 90px; color: rgba(120, 120, 120, 1);}
        50% {bottom: 84px; color: rgba(120, 120, 120, 1);}
    }
    
    @keyframes bounce {
        10% {bottom: 90px; color: rgba(255, 255, 255, 1)}
        50% {bottom: 84px; color: rgba(255, 255, 255, .6)}
    }
}
